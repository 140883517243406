import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ShipmentsPrint } from 'ui/component/orders-list/order-print';
import {
    ISalesLayoutConfig,
    salesLayoutConfigVar,
    currentOrderVar,
    shipmentIdVar,
} from '../../layouts/sales-layout/sales-layout-state';
import { findShipmentByNumber } from '../../util/process-order-entities';
import { SalesLayout } from '../../layouts/sales-layout';
import { OrderDetailsPageContent } from '../order-details-page-content';
import orderDetailsStyles from '../style.css';

const ShipmentPrintPage: React.FC = () => {
    const [pageTitle, setPageTitle] = useState<string>('');

    const t = usePhraseTranslater();

    const config: ISalesLayoutConfig = useReactiveVar(salesLayoutConfigVar);
    const currentOrder = useReactiveVar(currentOrderVar);
    const shipmentNumber = useReactiveVar(shipmentIdVar);

    const shipmentPrintPageComponent: JSX.Element = (
        <OrderDetailsPageContent>
            <ShipmentsPrint config={config} order={currentOrder} shipmentNumber={shipmentNumber} />
        </OrderDetailsPageContent>
    );

    useEffect(() => {
        if (!currentOrder) {
            return;
        }

        const {
            shipments,
        } = currentOrder;

        const updatedPageTitle = shipmentNumber !== ''
            ? t('Print Shipment #%1', (findShipmentByNumber(shipments, shipmentNumber)?.number || ''))
            : t('Print All Shipments');
        setPageTitle(updatedPageTitle);
    }, [currentOrder]);

    return (
        <SalesLayout
            pageKey="shipment-print"
            pageTitle={pageTitle}
            pageContent={shipmentPrintPageComponent}
            salesLayoutClassName={orderDetailsStyles.printPageLayout}
            showLeftSidebar={false}
            loadCart={false}
            loadCountries
            loadShipments
            isGuestPage={false}
            splitOrderItems={false}
        />
    );
};

export { ShipmentPrintPage as default };
